<template>
  <v-card class="mb-5">
    <custom-snackbar ref="snackbar" />
    <v-card-text class="py-0 px-0 px-md-4 py-md-4">
      <v-data-table
          ref="tabela"
          dense
          item-key="id"
          :headers="headers"
          :items="data"
          :loading="loading"
          :no-data-text="noData"
          :items-per-page.sync="perPage"
          :loading-text="$dataTableConfig.getLoadText()"
          :mobile-breakpoint="0"
          hide-default-footer
        >
          <template v-slot:progress>
            <v-progress-linear
              absolute
              indeterminate
              color="green"
            />
          </template>

          <template v-slot:[`item.criacao`]="{ item }">
            <div>
              {{ $date.validDate(item.dataCriacao) }}
            </div>
          </template>

          <template v-slot:[`item.exclusao`]="{ item }">
            <div>
              {{ $date.validDate(item.dataExclusao) }}
            </div>
          </template>

          <template v-slot:[`item.area`]="{ item }">
            <div>
              {{ $stringFormat.formatNumber(item.area, 0, 0) }}
            </div>
          </template>

        <template v-slot:[`item.janeiro`]="{ item }">
          <v-text-field
              v-model="item.janeiro"
              v-money="mask"
              label="Editar"
              single-line
              dense
              class="oferta-text-field col-m"
              :disabled="!editarOfertas || mesMinimoEdicao >= 1 || itemExcluido(item, 1)"
              reverse
              @keydown="$stringFormat.validarNumero"
          />
        </template>

        <template v-slot:[`item.fevereiro`]="{ item }">
          <v-text-field
              v-model="item.fevereiro"
              v-money="mask"
              label="Editar"
              single-line
              dense
              class="oferta-text-field col-m"
              :disabled="!editarOfertas || mesMinimoEdicao >= 2 || itemExcluido(item, 2)"
              reverse
              @keydown="$stringFormat.validarNumero"
          />
        </template>

        <template v-slot:[`item.marco`]="{ item }">
          <v-text-field
              v-model="item.marco"
              v-money="mask"
              label="Editar"
              single-line
              dense
              class="oferta-text-field col-m"
              :disabled="!editarOfertas || mesMinimoEdicao >= 3 || itemExcluido(item, 3)"
              reverse
              @keydown="$stringFormat.validarNumero"
          />
        </template>

        <template v-slot:[`item.abril`]="{ item }">
          <v-text-field
              v-model="item.abril"
              v-money="mask"
              label="Editar"
              single-line
              dense
              class="oferta-text-field col-m"
              :disabled="!editarOfertas || mesMinimoEdicao >= 4 || itemExcluido(item, 4)"
              reverse
              @keydown="$stringFormat.validarNumero"
          />
        </template>

        <template v-slot:[`item.maio`]="{ item }">
          <v-text-field
              v-model="item.maio"
              v-money="mask"
              label="Editar"
              single-line
              dense
              class="oferta-text-field col-m"
              :disabled="!editarOfertas || mesMinimoEdicao >= 5 || itemExcluido(item, 5)"
              reverse
              @keydown="$stringFormat.validarNumero"
          />
        </template>

        <template v-slot:[`item.junho`]="{ item }">
          <v-text-field
              v-model="item.junho"
              v-money="mask"
              label="Editar"
              single-line
              dense
              class="oferta-text-field col-m"
              :disabled="!editarOfertas || mesMinimoEdicao >= 6 || itemExcluido(item, 6)"
              reverse
              @keydown="$stringFormat.validarNumero"
          />
        </template>

        <template v-slot:[`item.julho`]="{ item }">
          <v-text-field
              v-model="item.julho"
              v-money="mask"
              label="Editar"
              single-line
              dense
              class="oferta-text-field col-m"
              :disabled="!editarOfertas || mesMinimoEdicao >= 7 || itemExcluido(item, 7)"
              reverse
              @keydown="$stringFormat.validarNumero"
          />
        </template>

        <template v-slot:[`item.agosto`]="{ item }">
          <v-text-field
              v-model="item.agosto"
              v-money="mask"
              label="Editar"
              single-line
              dense
              class="oferta-text-field col-m"
              :disabled="!editarOfertas || mesMinimoEdicao >= 8 || itemExcluido(item, 8)"
              reverse
              @keydown="$stringFormat.validarNumero"
          />
        </template>

        <template v-slot:[`item.setembro`]="{ item }">
          <v-text-field
              v-model="item.setembro"
              v-money="mask"
              label="Editar"
              single-line
              dense
              class="oferta-text-field col-m"
              :disabled="!editarOfertas || mesMinimoEdicao >= 9 || itemExcluido(item, 9)"
              reverse
              @keydown="$stringFormat.validarNumero"
          />
        </template>

        <template v-slot:[`item.outubro`]="{ item }">
          <v-text-field
              v-model="item.outubro"
              v-money="mask"
              label="Editar"
              single-line
              dense
              class="oferta-text-field col-m"
              :disabled="!editarOfertas || mesMinimoEdicao >= 10 || itemExcluido(item, 10)"
              reverse
              @keydown="$stringFormat.validarNumero"
          />
        </template>

        <template v-slot:[`item.novembro`]="{ item }">
          <v-text-field
              v-model="item.novembro"
              v-money="mask"
              label="Editar"
              single-line
              dense
              class="oferta-text-field col-m"
              :disabled="!editarOfertas || mesMinimoEdicao >= 11 || itemExcluido(item, 11)"
              reverse
              @keydown="$stringFormat.validarNumero"
          />
        </template>

        <template v-slot:[`item.dezembro`]="{ item }">
          <v-text-field
              v-model="item.dezembro"
              v-money="mask"
              label="Editar"
              single-line
              dense
              class="oferta-text-field col-m"
              :disabled="!editarOfertas || mesMinimoEdicao >= 12 || itemExcluido(item, 12)"
              reverse
              @keydown="$stringFormat.validarNumero"
          />
        </template>

        <template v-slot:[`item.total`]="{ item }">
          <div>
            {{ $stringFormat.formatNumber(
              somaMeses(item)
              , 0) }}
          </div>
        </template>

        <template v-if="data.length>0" slot="body.append">
          <tr class="custom-table--header">
            <th style="font-size: 12px;">Total</th>
            <th class="text-right" style="font-size: 12px;">{{ $stringFormat.formatNumber(computedAreaTotal, 0, 0) }}</th>
            <th class="text-center">-</th>
            <th class="text-center">-</th>
            <th class="text-right" style="font-size: 12px;">{{ $stringFormat.formatNumber(somaMes('janeiro'), 0, 0) }}</th>
            <th class="text-right" style="font-size: 12px;">{{ $stringFormat.formatNumber(somaMes('fevereiro'), 0, 0) }}</th>
            <th class="text-right" style="font-size: 12px;">{{ $stringFormat.formatNumber(somaMes('marco'), 0, 0) }}</th>
            <th class="text-right" style="font-size: 12px;">{{ $stringFormat.formatNumber(somaMes('abril'), 0, 0) }}</th>
            <th class="text-right" style="font-size: 12px;">{{ $stringFormat.formatNumber(somaMes('maio'), 0, 0) }}</th>
            <th class="text-right" style="font-size: 12px;">{{ $stringFormat.formatNumber(somaMes('junho'), 0, 0) }}</th>
            <th class="text-right" style="font-size: 12px;">{{ $stringFormat.formatNumber(somaMes('julho'), 0, 0) }}</th>
            <th class="text-right" style="font-size: 12px;">{{ $stringFormat.formatNumber(somaMes('agosto'), 0, 0) }}</th>
            <th class="text-right" style="font-size: 12px;">{{ $stringFormat.formatNumber(somaMes('setembro'), 0, 0) }}</th>
            <th class="text-right" style="font-size: 12px;">{{ $stringFormat.formatNumber(somaMes('outubro'), 0, 0) }}</th>
            <th class="text-right" style="font-size: 12px;">{{ $stringFormat.formatNumber(somaMes('novembro'), 0, 0) }}</th>
            <th class="text-right" style="font-size: 12px;">{{ $stringFormat.formatNumber(somaMes('dezembro'), 0, 0) }}</th>
            <th class="text-right" style="font-size: 12px;">{{ $stringFormat.formatNumber(somaMesTotal(), 0, 0) }}</th>
          </tr>
        </template>
      </v-data-table>
      <v-row class="m-5 p-2">
        <v-col cols="4">
          Última Atualização:  <strong>{{ lastUpdate.updated_at }}</strong>
        </v-col>
        <v-col cols="4">
          Realizada por:  <strong>{{ lastUpdate.username }}</strong>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { VMoney } from 'v-money'

import ApiService from '@/core/services/api.service'
import { can } from '@/core/services/storage.service'

export default {
  directives: { money: VMoney },
  props: {
    headers: {
      type: Object,
    },
    filtros: {
      type: Object,
    },
    editarOfertas: {
      type: Boolean,
      default: false,
    },
    salvarOfertas: {
      type: Boolean,
      default: false,
    },
    descartarOfertas: {
      type: Boolean,
      default: false,
    },
  },
  data: (instance) => ({
    // General
    loading: false,
    noData: 'Nenhuma informação encontrada',
    // Form Data
    data: [],
    lastUpdate: [],
    // Expand Data Table
    perPage: instance.$dataTableConfig.getItemsPerPage(),
    page: 1,
    totalItems: 16,
    mask: {
      decimal: '',
      thousands: '.',
      prefix: '',
      suffix: '',
      precision: 0,
    },
  }),
  watch: {
    filtros () {
      this.$router.push({ query: this.filtros, silent: true }).catch((e) => e)
      this.getData()
    },
    salvarOfertas (val) {
      if (val) {
        this.atualizarOfertas()
        this.$emit('update:salvarOfertas', false)
      }
    },
    descartarOfertas (val) {
      if (val) {
        this.getData()
        this.$emit('update:descartarOfertas', false)
      }
    },
  },
  computed: {
    mesMinimoEdicao: {
      get () {
        const anoAtual = new Date().getFullYear()
        const mesAtual = new Date().getMonth()

        if (this.filtros.ano < anoAtual) {
          return 12
        }

        if (this.filtros.ano > anoAtual) {
          return 0
        }

        return mesAtual
      },
    },

    computedAreaTotal () {
      let total = 0

      this.data.forEach((item) => {
        total += item.area
      })

      return total
    }
  },
  mounted () {
    this.getData()

    this.$refs.tabela.$el.querySelector('thead').addEventListener('wheel', (evt) => {
      evt.preventDefault()
      this.$refs.tabela.$el.querySelector('.v-data-table__wrapper').scrollLeft += evt.deltaY
    })
  },

  methods: {
    can,
    getData () {
      this.loading = true
      this.data = []
      this.$emit('update:editar-ofertas', false)

      ApiService
        .cancel('ofertas-gramas')
        .get(
          '/agricola/oferta-grama',
          this.filtros,
        )
        .then((res) => {
          this.data = res.data.data

          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })

      this.getLastUpdate()
    },
    getLastUpdate () {
      const params = {
        empresa_id: this.filtros.empresaId,
        producao_id: this.filtros.producaoId,
      }

      ApiService
        .query(`/oferta-grama/last-update/${this.filtros.ano}`, { params })
        .then((response) => {
          this.lastUpdate = response.data.data
        })
        .catch(() => {
        })
    },
    async atualizarOfertas () {
      for (const item of this.data) {
        if (item.oferta_grama_id) {
          this.$refs.snackbar.show('Atualizando oferta de gramas', `Atualizando oferta do talhão: ${item.talhao}`, 'alert', -1)

          await ApiService
            .put(
              `/agricola/oferta-grama/${item.oferta_grama_id}`,
              {
                janeiro: this.converteValor(item.janeiro),
                fevereiro: this.converteValor(item.fevereiro),
                marco: this.converteValor(item.marco),
                abril: this.converteValor(item.abril),
                maio: this.converteValor(item.maio),
                junho: this.converteValor(item.junho),
                julho: this.converteValor(item.julho),
                agosto: this.converteValor(item.agosto),
                setembro: this.converteValor(item.setembro),
                outubro: this.converteValor(item.outubro),
                novembro: this.converteValor(item.novembro),
                dezembro: this.converteValor(item.dezembro),
              },
            )

          this.$refs.snackbar.close()
          continue
        }

        this.$refs.snackbar.show('Atualizando oferta de gramas', `Adicionando oferta no talhão: ${item.talhao}`, 'alert', -1)

        await ApiService.post(
          '/agricola/oferta-grama',
          {
            talhaoId: item.talhao_id,
            ano: item.ano,
            janeiro: this.converteValor(item.janeiro),
            fevereiro: this.converteValor(item.fevereiro),
            marco: this.converteValor(item.marco),
            abril: this.converteValor(item.abril),
            maio: this.converteValor(item.maio),
            junho: this.converteValor(item.junho),
            julho: this.converteValor(item.julho),
            agosto: this.converteValor(item.agosto),
            setembro: this.converteValor(item.setembro),
            outubro: this.converteValor(item.outubro),
            novembro: this.converteValor(item.novembro),
            dezembro: this.converteValor(item.dezembro),
          },
        )

        this.$refs.snackbar.close()
      }

      this.$refs.snackbar.show('Atualização concluida', 'Ofertas atualizadas', 'success', 5000)
      this.getData()
    },
    validDate (value) {
      if (value == null) {
        return '-'
      }

      return value
    },
    getColor (boolField) {
      return boolField === 0 || boolField === false ? 'danger' : 'success'
    },

    getText (aplicacaoParcial) {
      return aplicacaoParcial === 0 || aplicacaoParcial === false ? 'Não' : 'Sim'
    },
    itemExcluido (item, mes) {
      if (!item.dataExclusao) {
        return false
      }

      return (new Date(item.dataExclusao)).getMonth() < mes
    },
    somaMeses (item) {
      if (!item) {
        return 0
      }

      return this.converteValor(item.janeiro) +
        this.converteValor(item.fevereiro) +
        this.converteValor(item.marco) +
        this.converteValor(item.abril) +
        this.converteValor(item.maio) +
        this.converteValor(item.junho) +
        this.converteValor(item.julho) +
        this.converteValor(item.agosto) +
        this.converteValor(item.setembro) +
        this.converteValor(item.outubro) +
        this.converteValor(item.novembro) +
        this.converteValor(item.dezembro)
    },
    converteValor (valor) {
      if (!valor) {
        return 0
      }

      if (typeof valor === 'string') {
        return Number.parseInt(valor.replace(/\D/g, ''))
      }

      return valor
    },

    somaMes (mes) {
      let total = 0

      this.data.forEach((item) => {
        total += this.converteValor(item[mes])
      })

      return total
    },

    somaMesTotal () {
      let total = 0

      const somaMeses = this.somaMeses

      this.data.forEach((item) => {
        total += somaMeses(item)
      })

      return total
    }
  },
}
</script>
