<template>
  <div>
    <v-toolbar flat class="d-flex flex-row-reverse pb-5" color="rgba(0,0,0,0)">
      <v-btn
          outlined
          @click="confirmarEdicaoOfertas"
          :disabled="!habilitaEdicao"
          v-if="can('agricola.oferta-grama.update') && !editarOfertas">
        Editar Ofertas
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn
          outlined
          @click="salvarEdicaoOfertas"
          :disabled="!habilitaEdicao"
          v-if="can('agricola.oferta-grama.update') && editarOfertas">
        Salvar Edições
        <v-icon>mdi-check</v-icon>
      </v-btn>
      <v-btn
          outlined
          class="ml-4"
          color="red"
          @click="descartarEdicaoOfertas"
          :disabled="!habilitaEdicao"
          v-if="can('agricola.oferta-grama.update') && editarOfertas">
        Descartar Edições
        <v-icon>mdi-cancel</v-icon>
      </v-btn>
    </v-toolbar>
  </div>
</template>

<script>
import { can } from '@/core/services/storage.service'

export default {
  props: {
    value: {
      type: Object,
    },
    filtros: {
      type: Object,
    },
    editarOfertas: {
      type: Boolean,
      default: false,
    },
  },
  data: () => {
    return {
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
    habilitaEdicao: {
      get () {
        return this.filtros.producaoId > 0 && this.filtros.ano > 2014 && this.filtros.ano >= (new Date()).getFullYear()
      },
    },
  },
  methods: {
    can,
    confirmarEdicaoOfertas () {
      this.$emit('update:editar-ofertas', true)
    },
    salvarEdicaoOfertas () {
      this.$emit('update:editar-ofertas', false)
      this.$emit('salvar-ofertas')
    },
    descartarEdicaoOfertas () {
      this.$emit('update:editar-ofertas', false)
      this.$emit('descartar-ofertas')
    },
  },
}
</script>

<style scoped>

</style>
