<template>
  <div>
    <v-form ref="form">
      <FiltroMobile :has-change="change" @search="seachFilter">
        <v-col class="pb-0 col-12 col-md-6 col-lg-4">
          <EmpresasSelect
              v-model="empresaId"
              @change="change = true"
              obrigatorio
          />
        </v-col>
        <v-col class="pb-0 col-12 col-md-6 col-lg-4">
          <ProducoesSelect
              v-model="producaoId"
              :empresa="empresaId"
              :disabled="!empresaId"
              @change="change = true"
              obrigatorio
          />
        </v-col>
        <v-col class="pb-0 col-12 col-md-6 col-lg-2">
          <v-text-field
              dense
              outlined
              label="Ano"
              type="number"
              min="2011"
              v-model="ano"
              @change="change = true"
          />
        </v-col>
      </FiltroMobile>
    </v-form>
  </div>
</template>

<script>

export default {
  props: {
    value: {
      type: Object,
    },
  },
  data: () => {
    return {
      change: false,
      empresaId: '',
      producaoId: '',
      ano: '',
    }
  },
  mounted () {
    this.empresaId = this.value?.empresaId
    this.producaoId = this.value?.producaoId
    this.ano = this.value?.ano
  },
  watch: {
    value (val) {
      this.empresaId = val?.empresaId
      this.producaoId = val?.producaoId
      this.ano = val?.ano
    },
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
        this.$emit('change', val)
      },
    },
  },
  methods: {
    seachFilter () {
      if (!this.$refs.form.validate()) {
        return false
      }

      this.model = {
        empresaId: this.empresaId,
        producaoId: this.producaoId,
        ano: this.ano,
      }

      this.change = false
    },
  },
}
</script>

<style scoped>

</style>
