<template>
<div>
  <custom-snackbar ref="snackbar" />
  <ActionsComponent
      v-model="headers"
      :filtros="filtros"
      :editar-ofertas.sync="editarOfertas"
      @salvar-ofertas="salvarOferta"
      @descartar-ofertas="descartarOferta"
  />
  <FilterComponent v-model="filtros"/>
  <ListComponent :filtros="filtros" :headers="headers" :editar-ofertas.sync="editarOfertas" :salvar-ofertas.sync="salvarOfertas" :descartar-ofertas.sync="descartarOfertas"/>
</div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'

import ActionsComponent from './components/ActionsComponent'
import FilterComponent from './components/FilterComponent'
import ListComponent from './components/ListComponent'

export default {
  components: { ActionsComponent, FilterComponent, ListComponent },
  data: (instance) => ({
    editarOfertas: false,
    salvarOfertas: false,
    descartarOfertas: false,
    filtros: {
      empresaId: instance.filtroValue(instance.$route.query.empresaId, '', 'int'),
      producaoId: instance.filtroValue(instance.$route.query.producaoId, '', 'int'),
      ano: instance.filtroValue(instance.$route.query.ano, new Date().getFullYear(), ''),
    },
    headers: [
      { text: 'Talhão', value: 'talhao', class: 'custom-table--header' },
      { text: 'Área m²', value: 'area', align: 'right', class: 'custom-table--header' },
      { text: 'Criação', value: 'criacao', align: 'center', class: 'custom-table--header' },
      { text: 'Exclusão', value: 'exclusao', align: 'center', class: 'custom-table--header' },
      { text: 'JAN', value: 'janeiro', align: 'right', class: 'custom-table--header' },
      { text: 'FEV', value: 'fevereiro', align: 'right', class: 'custom-table--header' },
      { text: 'MAR', value: 'marco', align: 'right', class: 'custom-table--header' },
      { text: 'ABR', value: 'abril', align: 'right', class: 'custom-table--header' },
      { text: 'MAI', value: 'maio', align: 'right', class: 'custom-table--header' },
      { text: 'JUN', value: 'junho', align: 'right', class: 'custom-table--header' },
      { text: 'JUL', value: 'julho', align: 'right', class: 'custom-table--header' },
      { text: 'AGO', value: 'agosto', align: 'right', class: 'custom-table--header' },
      { text: 'SET', value: 'setembro', align: 'right', class: 'custom-table--header' },
      { text: 'OUT', value: 'outubro', align: 'right', class: 'custom-table--header' },
      { text: 'NOV', value: 'novembro', align: 'right', class: 'custom-table--header' },
      { text: 'DEZ', value: 'dezembro', align: 'right', class: 'custom-table--header' },
      { text: 'Total', value: 'total', align: 'right', class: 'custom-table--header' },
    ],
  }),
  mounted () {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Agrícola', route: 'agricola' },
      { title: 'Oferta de grama' },
    ])
  },
  methods: {
    salvarOferta () {
      this.editarOfertas = false
      this.salvarOfertas = true
    },
    descartarOferta () {
      this.editarOfertas = false
      this.descartarOfertas = true
    },
    filtroValue (value, valorPadrao, type) {
      if (value === undefined || value === null || value === '') {
        return valorPadrao
      }

      if (type === 'int') {
        return parseInt(value)
      }

      if (type === 'float') {
        return parseFloat(value)
      }

      if (type === 'array') {
        return [].concat(value)
      }

      return value
    },
  },
}
</script>

<style scoped>

</style>
